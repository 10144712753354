// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { auth } from "./firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import Login from "./Login";
import Home from "./Home";
import ContactUs from "./ContactUs";
import Subscribe from "./Subscribe";
import Apps from "./Apps";
import Admin from "./Admin";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_live_51NFfiFCoObxT3KRueNqwExjspkjhGTnB8iA27N9ONPZIPC5hzxOmjW2oRJKinjhwliBwdZQLi0C0Avg7QLP5Q0eA00Jrexr217");

const App = () => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/support" element={<ContactUs />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/admin" element={user ? <Admin user={user} /> : <Login />} />
          <Route path="/" element={user ? <Home user={user} /> : <Login />} />
          <Route path="/delete" element={user ? <Home user={user} /> : <Login />} />
          <Route path="/subscribe" element={user ? <Subscribe user={user} /> : <Login />} />
        </Routes>
      </Router>
    </Elements >
  );
};

export default App;



