import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { getDatabase, ref, runTransaction, get } from "firebase/database";

const Apps = () => {
  useEffect(() => {
    // Extract the query parameter
    const params = new URLSearchParams(window.location.search);
    let appParamCheck = params.get("qrscan");

    if (!appParamCheck) {
      appParamCheck = 'default';
    }
    const appParam = appParamCheck;
    const db = getDatabase();
    console.log(appParam);
    if (appParam) {
      let sourceDevice;
      if (isAndroid) {
        sourceDevice = "android";
      } else if (isIOS) {
        sourceDevice = "ios";
      } else {
        sourceDevice = "other";
      }
      const noAuthref = ref(db, `/noauth/qrscan/${sourceDevice}/${appParam}/scanned`);
      console.log(noAuthref);

      // Check if the item exists and update accordingly
      runTransaction(noAuthref, (currentData) => {
        if (currentData === null || typeof currentData !== 'number') {
          return 1; // if it doesn't exist or is not a number, set it to 1
        }
        return currentData + 1; // if it exists, increment by 1
      }).then(() => {
        // After the transaction is completed, execute the redirection logic
        if (appParam.includes("video")) {
          const videoRef = ref(db, `/noauth/videos/${appParam}/url`);
          get(videoRef).then((snapshot) => {
            if (snapshot.exists()) {
              const url = snapshot.val();
              window.location.href = url;
            } else {
              console.log('URL does not exist in Firebase');
            }
          }).catch((error) => {
            console.error("Error fetching URL from Firebase:", error);
          });
        } else {
          if (isAndroid) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.mybmxracer.mobile";
          } else if (isIOS) {
            window.location.href = "https://apps.apple.com/us/app/mybmxracer/id6449098434";
          } else {
            window.location.href = "https://mybmxracer.com/subscribe";
          }
        }
      });
    }
  }, []);

  return <div>Redirecting...</div>;
};

export default Apps;
