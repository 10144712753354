// src/useLiveDataContext.js
import { createContext, useContext, useState, useEffect } from "react";
import { database } from "./firebaseConfig";
import { ref, onValue, off } from "firebase/database";

const LiveDataContext = createContext();

export const useLiveData = () => {
  const context = useContext(LiveDataContext);
  if (!context) {
    throw new Error("useLiveData must be used within a LiveDataProvider");
  }
  return context;
};

export const LiveDataProvider = ({ children }) => {
  const [liveData, setLiveData] = useState({});

  useEffect(() => {
    const liveDataRef = ref(database, "livedata/0");

    const listener = onValue(liveDataRef, (snapshot) => {
      const liveDataValue = snapshot.val();
      if (liveDataValue) {
        setLiveData(liveDataValue);
      } else {
        setLiveData({});
      }
    });

    return () => {
      off(liveDataRef, "value", listener);
    };
  }, []);

  return (
    <LiveDataContext.Provider value={liveData}>
      {children}
    </LiveDataContext.Provider>
  );
};
