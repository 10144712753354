import React, { useEffect, useState } from 'react';
import { auth, firestore, functions } from "./firebaseConfig";
import { collection, query, where, onSnapshot, addDoc, getDocs } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { httpsCallable } from 'firebase/functions';
import iconImage from './Assets/logo512.png';
import iconImage2 from './Assets/logo192.png';
import "./Subscribe.css";  // Import CSS

const Subscribe = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [interval, setInterval] = useState('year');
  const [returnUrl, setReturnUrl] = useState(window.location.origin + '/subscribe');
  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday



  const fetchProducts = async () => {
    const productsRef = collection(firestore, 'products');
    const productSnapshot = await getDocs(productsRef);
    const products = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Get prices for each product
    const productsWithPrices = await Promise.all(products.map(async product => {
      const pricesRef = collection(firestore, 'products', product.id, 'prices');
      const priceSnapshot = await getDocs(pricesRef);
      const prices = priceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      return {
        ...product,
        prices: prices.filter(price => price.interval === interval && price.active === true),

      };
    }));

    setProducts(productsWithPrices);
  };

  useEffect(() => {
    fetchProducts();
  }, [interval]);

  useEffect(() => {
    const checkSubscription = async () => {
      if (!user) return;

      const q = query(
        collection(firestore, 'customers', user.uid, 'subscriptions'),
        where('status', 'in', ['trialing', 'active'])
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
          const subscriptionData = snapshot.docs[0].data();
          setIsSubscribed(true);
          setSubscriptionDetails({
            name: subscriptionData.items[0].price.product.name,
            price: subscriptionData.items[0].price.unit_amount / 100, // assuming the price is in cents
            renewalDate: new Date(subscriptionData.current_period_end.seconds * 1000)
          });
        } else {
          setIsSubscribed(false);
          setSubscriptionDetails({});
        }
      });

      // Cleanup
      return unsubscribe;
    };

    checkSubscription();
  }, [user]);

  const handleSignOut = () => {
    signOut(auth);
  };

  const handleToggle = () => {
    setInterval(interval === 'year' ? 'day' : 'year');
  }

  const createCheckoutSession = async (priceId, productname) => {
    setIsLoading(true);
    if (!user) return;
    let allow_promo = false;
    if (interval === 'year' && productname === 'Expert') {
      allow_promo = true;
    }

    try {
      const docRef = await addDoc(collection(firestore, 'customers', user.uid, 'checkout_sessions'), {
        price: priceId,
        allow_promotion_codes: allow_promo,
        success_url: returnUrl,
        cancel_url: returnUrl,
      });

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          alert(`An error occured: ${error.message}`);
          setIsLoading(false);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setIsLoading(false);
    }
  };

  const redirectToPortal = async () => {
    setIsLoading(true);
    const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    const { data } = await functionRef({
      returnUrl: returnUrl,
      locale: "auto",
    });
    window.location.assign(data.url);
    setIsLoading(false);
  };

  const filteredProducts = products.filter(product =>
    (product.prices ? product.prices.some(price => price.interval === interval && price.active === true) : false)
  );

  return (
    <div className='container'>
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <>
          <img src={iconImage2} alt="myBMXracer" />
          <header className='header'>myBMXracer</header>
          {isSubscribed ? (
            <div>
              <div>Current Subscription</div>
              <div>{subscriptionDetails.name}</div>
              <div>Expiration Date: {subscriptionDetails.renewalDate && subscriptionDetails.renewalDate.toLocaleDateString()}</div>
              <button className="subscribeButton" onClick={redirectToPortal}>Manage Subscription</button>
            </div>
          ) : (
            <div>
              {(currentDay === 0 || currentDay === 5 || currentDay === 6) && (
                <div className="toggle-container">
                  <span className="toggle-label">Weekend Pass</span>
                  <label className="switch">
                    <input type="checkbox" checked={interval === 'year'} onChange={handleToggle} />
                    <span className="slider round"></span>
                  </label>
                  <span className="toggle-label">Annual</span>
                </div>
              )}
              <div className="product-container">
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <div key={product.id} className="product-card">
                      {product.prices.filter(price => price.interval === interval && price.active === true).map((price) => (
                        <div>
                          <h1>{product.name}</h1>
                          <div className="product-description">
                            {product.name === 'Expert' &&
                              <>
                                <div className="description-row">
                                  <div className="description-title">All Novice features PLUS:</div>
                                </div>
                                <div className="description-row">
                                  <div className="description-title">TEAM TRACKER</div>
                                  <div className="description-text">add full team with 1 click, create your own team list (ie. daughters friends, home track)</div>
                                </div>
                                <div className="description-row">
                                  <div className="description-title">KEEP NOTES</div>
                                  <div className="description-text">Track notes of gear, rider stance, rollout calculator etc.</div>
                                </div>
                                <div className="description-row">
                                  <div className="description-title">UNLIMITED RIDERS</div>
                                  <div className="description-text">Keep track and follow unlimited riders throughout the weekend</div>
                                </div>
                                <div className="description-row">
                                  <div className="description-title">AI RULEBOOK</div>
                                  <div className="description-text">Get Answers instantly to your rulebook based questions (rules, points, deadlines etc)</div>
                                </div>
                                <br></br>
                              </>
                            }
                            {product.name !== 'Expert' &&
                              <>
                                {product.name === 'Intermediate' &&
                                <div className="description-row">
                                  <div className="description-title">FOLLOW</div>
                                  <div className="description-text">Follow 6 racers (upgrade to Expert to follow unlimited riders)</div>
                                </div>
                                }
                                {product.name === 'Novice' &&
                                  <div className="description-row">
                                    <div className="description-title">FOLLOW</div>
                                    <div className="description-text">Follow 2 racers (upgrade to Intermediate to follow 6 riders)</div>
                                  </div>
                                }
                                <div className="description-row">
                                  <div className="description-title">NOTIFICATIONS</div>
                                  <div className="description-text">Get notification and live countdown to see how many motos until they race</div>
                                </div>
                                <div className="description-row">
                                  <div className="description-title">LIVE STREAM</div>
                                  <div className="description-text">One Click access to watch the live stream, so you never miss a moment</div>
                                </div>
                                <div className="description-row">
                                  <div className="description-title">MOTO INFO</div>
                                  <div className="description-text">Easily see moto numbers, transfers, and compedtitor points and class info(NAG,NAT,etc)</div>
                                </div>
                                <div className="description-row">
                                  <div className="description-title">POINT INFO</div>
                                  <div className="description-text">Weekend points calculated for you, trophy displayed by podium rider name</div>
                                </div>
                              </>
                            }
                          </div>
                          <h3>${price.unit_amount / 100} {price.currency.toUpperCase()}</h3>
                          <button
                            key={price.id}
                            className="subscribeButton"
                            onClick={() => createCheckoutSession(price.id, product.name)}
                          >
                            Subscribe
                          </button>
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <div><h2>Only available between Friday-Sunday during Nationals Weekends</h2></div>
                )}
              </div>
            </div>
          )}
          <button className="signOutButton" onClick={handleSignOut}>Sign Out</button>
        </>
      )}
    </div>
  );

};

export default Subscribe;

