// src/useTrackDataContext.js
import { createContext, useContext, useState, useEffect } from "react";

const TrackDataContext = createContext();

export const useTrackData = () => {
  const context = useContext(TrackDataContext);
  if (!context) {
    throw new Error("useTrackData must be used within a TrackDataProvider");
  }
  return context;
};

export const TrackDataProvider = ({ children }) => {
  const [trackData, setTrackData] = useState({});

  const fetchData = (jsonFile) => {
    fetch(jsonFile)
      .then((response) => response.json())
      .then((data) => setTrackData(data));
  };

  useEffect(() => {
    const jsonFile = "https://motoboards.usabmx.com/events/track_9000.json";
    fetchData(jsonFile);
  }, []);

  return (
    <TrackDataContext.Provider value={{ trackData, fetchData }}>
      {children}
    </TrackDataContext.Provider>
  );
};
