import React, { useCallback } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";
import { getDatabase, ref, set, get } from "firebase/database";
import { auth } from "./firebaseConfig";
import "./Login.css";
import googleIcon from './Assets/google-icon.png';
import appleIcon from './Assets/apple-icon.png';

const db = getDatabase();  // initialize your firebase realtime database

const Login = () => {
  const storeUserData = useCallback(async (user) => {
    const userRef = ref(db, `users/${user.uid}`);
    const snapshot = await get(userRef);

    if (!snapshot.exists()) {
      // Prepare basic user data
      const userData = {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        followed_riders: "",
        tierLevel: "None",
        // specify trialExpirationDate as needed
      };

      set(userRef, userData);
    }
  }, []);

  const handleSignInWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    try {
      const { user } = await signInWithPopup(auth, provider);
      storeUserData(user);
    } catch (error) {
      console.error(error);
    }
  }, [storeUserData]);

  const handleSignInWithApple = useCallback(async () => {
    const provider = new OAuthProvider('apple.com');
    try {
      const { user } = await signInWithPopup(auth, provider);
      storeUserData(user);
    } catch (error) {
      console.error(error);
    }
  }, [storeUserData]);

  return (
    <div className="login-container">
      <h1 className="login-title">myBMXracer</h1>
      <button onClick={handleSignInWithGoogle}>
        <img src={googleIcon} alt="Google Sign-In" />
        Sign in with Google
      </button>
      <button onClick={handleSignInWithApple}>
        <img src={appleIcon} alt="Apple Sign-In" />
        Sign in with Apple
      </button>
    </div>
  );
};

export default Login;


