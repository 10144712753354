// src/Admin.js
import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from "firebase/database";
import axios from 'axios';
import { FaTrashAlt } from 'react-icons/fa';

const Admin = ({ user }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scripts, setScripts] = useState([]);
  const [newScriptName, setNewScriptName] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [statuses, setStatuses] = useState({});
  const [logs, setLogs] = useState({});
  const [numLines, setNumLines] = useState('15');

  const checkAuth = async (apiKey) => {
    try {
      const response = await axios.get('https://mybmxracer.com/api/check-auth', {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });
      return response.status === 200;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        setIsAdmin(snapshot.val().admin);
        setApiKey(snapshot.val().adminApiKey);
      }
      setLoading(false);
    };

    if (user) {
      fetchUserDetails();
    } else {
      setLoading(false);
    }

    const cachedScripts = JSON.parse(localStorage.getItem('scripts')) || [];
    setScripts(cachedScripts);
  }, [user]);

  useEffect(() => {
    const verifyAuth = async () => {
      const authStatus = await checkAuth(apiKey);
      setIsAuthorized(authStatus);
    };

    if (apiKey) {
      verifyAuth();
    }
  }, [apiKey]);

  useEffect(() => {
    localStorage.setItem('scripts', JSON.stringify(scripts));
  }, [scripts]);

  useEffect(() => {
    scripts.forEach((scriptName) => {
      updateLogs(scriptName, numLines);
      updateStatus(scriptName);
    });
    const statusInterval = setInterval(() => {
      scripts.forEach((scriptName) => {
        updateLogs(scriptName, numLines);
        updateStatus(scriptName);
      });
    }, 5000);
    return () => clearInterval(statusInterval);
  }, [scripts, apiKey, numLines]);

  const updateLogs = async (scriptName, numLines) => {
    try {
      const response = await axios.get(`https://mybmxracer.com/api/logs/${scriptName}/${numLines}`, {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });
      setLogs((prevLogs) => ({ ...prevLogs, [scriptName]: response.data }));
    } catch (error) {
      console.error('Error updating logs:', error);
    }
  };

  const handleStart = async (scriptName) => {
    try {
      await axios.get(`https://mybmxracer.com/api/start/${scriptName}`, {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });
    } catch (error) {
      console.error('Error starting script:', error);
    }
  };

  const handleStop = async (scriptName) => {
    try {
      await axios.get(`https://mybmxracer.com/api/stop/${scriptName}`, {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });
    } catch (error) {
      console.error('Error stopping script:', error);
    }
  };

  const handleRestart = async (scriptName) => {
    try {
      await axios.get(`https://mybmxracer.com/api/restart/${scriptName}`, {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });
    } catch (error) {
      console.error('Error restarting script:', error);
    }
  };

  const handleAddScript = () => {
    if (newScriptName !== '') {
      setScripts([...scripts, newScriptName]);
      setNewScriptName('');
    }
  };

  const updateStatus = async (scriptName) => {
    try {
      const response = await axios.get(`https://mybmxracer.com/api/status/${scriptName}`, {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });
      setStatuses((prevStatuses) => ({ ...prevStatuses, [scriptName]: response.data }));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleRemoveScript = (scriptName) => {
    setScripts(scripts.filter((script) => script !== scriptName));
  };

  const retrieveScripts = async () => {
    try {
      const response = await axios.get('https://mybmxracer.com/api/scripts', {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });

      if (response.status === 200) {
        const serverScripts = response.data;
        serverScripts.forEach(scriptName => {
          if (!scripts.includes(scriptName)) {
            setScripts(prevScripts => [...prevScripts, scriptName]);
          }
        });
      }
    } catch (error) {
      console.error('Error retrieving scripts:', error);
    }
  };


  const handleScriptNameChange = (e) => {
    setNewScriptName(e.target.value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || !isAdmin) {
    return <div>You are not authorized to view this page.</div>;
  }

  if (isAuthorized) {
    return (
      <div>
        <h1>Python Script Manager</h1>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
          <input
            value={newScriptName}
            onChange={(e) => setNewScriptName(e.target.value)}
            type="text"
            placeholder="Enter script name"
            style={{ flex: "2 0 auto", marginRight: "10px" }}
          />
          <button onClick={handleAddScript} style={{ flex: "1 0 auto", marginRight: "10px" }}>Add Script</button>
          <button onClick={retrieveScripts} style={{ flex: "1 0 auto" }}>Retrieve Scripts</button>
          <input
            value={numLines}
            onChange={(e) => setNumLines(e.target.value)}
            type="text"
            placeholder="Number of lines"
            style={{ flex: "2 0 auto", marginRight: "10px" }}
          />
        </div>

        <div id="scriptList">
          {scripts.map((scriptName) => (
            <div key={scriptName} style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', margin: '10px 0' }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h2 style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                  {statuses[scriptName] === 'running' ? '🟢' : '🔴'}
                  {scriptName}
                </h2>
                {statuses[scriptName] === 'running' ?
                  <button onClick={() => handleStop(scriptName)}>Stop</button> :
                  <button onClick={() => handleStart(scriptName)}>Start</button>
                }
                <span onClick={() => handleRemoveScript(scriptName)} style={{ cursor: "pointer", marginLeft: "10px" }}>
                  <FaTrashAlt />
                </span>
              </div>
              <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                <pre>{logs[scriptName]}</pre>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return <div>Unauthorized</div>;
  }
};

export default Admin;
