// src/firebaseConfig.js
// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFunctions } from '@firebase/functions';
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBITUFU5goyJmyl4LTjTHI2g1FKvm_2Mts",
  authDomain: "mybmxracer.firebaseapp.com",
  databaseURL: "https://mybmxracer-default-rtdb.firebaseio.com",
  projectId: "mybmxracer",
  storageBucket: "mybmxracer.appspot.com",
  messagingSenderId: "855155266595",
  appId: "1:855155266595:web:e2ff31e919aea9b7b38d96",
  measurementId: "G-L3WD6Y4VS6"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const database = getDatabase(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);

export { app, auth, database, functions, firestore };