import React from 'react';
import './ContactUs.css';

const ContactUs = () => (
  <div className="contactUs">
    <h1>Contact Us</h1>
    <p>Name: Mark Dahlhauser</p>
    <p>Phone: 404-717-5659</p>
    <h1>Support</h1>
    <p>Email: <a href="mailto:mdahlhauser@gmail.com">mdahlhauser@gmail.com</a></p>
  </div>
);

export default ContactUs;
