// src/Home.js
import React, { useState, useEffect } from "react";
import { auth } from "./firebaseConfig";
import { signOut } from "firebase/auth";
import { useTrackData } from "./useTrackDataContext";
import { useLiveData } from "./useLiveDataContext";
import RidersTable from "./RidersTable";
import "./Home.css";  // Import CSS

const Home = ({ user }) => {
  const { trackData, fetchData } = useTrackData();
  const liveData = useLiveData();

  const handleSignOut = () => {
    signOut(auth);
  };

  const handleDeleteAccount = () => {
    if (window.confirm('Are you sure you want to delete your account?')) {
      auth.currentUser.delete().then(() => {
        window.alert('Account deleted.');
        handleSignOut();  // sign out after deletion
      }).catch(error => {
        console.error('Error deleting account', error);
        // Possible error handling. For instance, reauthenticate user if required.
      });
    }
  };

  const handleRefreshData = () => {
    fetchData();
  };

  const raceId = "46ACF626-8877-4664-85BD-C206F07C124E";
  const race = trackData.races && trackData.races[raceId];

  const findMotogroupKeysWithRider = (firstName, lastName) => {
    if (!race || !race.motogroups) {
      return [];
    }

    const searchFirstName = firstName.toUpperCase().trim();
    const searchLastName = lastName.toUpperCase().trim();

    return Object.entries(race.motogroups)
      .filter(([key, motogroup]) =>
        Object.values(motogroup.riders || {}).some(
          (rider) =>
            rider.first_name.toUpperCase().trim() === searchFirstName &&
            rider.last_name.toUpperCase().trim() === searchLastName
        )
      )
      .map(([key]) => key);
  };

  const firstName = "Ozzie";
  const lastName = "Rowe";
  const motogroupKeysWithRider = findMotogroupKeysWithRider(firstName, lastName);
  const motogroupKey = "AFFAF26A-1DBB-442F-9FA0-EB8079783F78";

  return (
    <div className="home-container">
      <div>
        <h1>Welcome to myBMXracer,</h1>
        <h1>{user.email}!</h1>
      </div>
      <div>
        <button onClick={handleSignOut}>Sign Out</button>
      </div>
    </div>
  );
};

export default Home;



/*
  return (
    <div>
      <h1>Welcome, {user.email}!</h1>
      <h2>Motogroup Keys with Rider {firstName} {lastName}:</h2>
      {motogroupKeysWithRider.length > 0 ? (
        <ul>
          {motogroupKeysWithRider.map((key) => (
            <li key={key}>{key}</li>
          ))}
        </ul>
      ) : (
        <p>No motogroups found with the specified rider.</p>
      )}
      <h2>Riders Table:</h2>
      <RidersTable motogroupKey={motogroupKey} />
      <button onClick={handleRefreshData}>Refresh Data</button>
      <button onClick={handleSignOut}>Sign Out</button>
    </div>
  );

*/

